<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import zango from "zangodb"
import Web3 from "web3";
let db = new zango.Db('mydb', { people: ['age'] });
let people = db.collection('people');
const chain_list_url = 'https://chainid.network/chains.json'
export default {
  mounted(){
let self = this
people.find().forEach(e=>{
   window.console.log('insert',e)
})
  },
  methods:{
    
  }
}
</script>
<style lang="scss">

</style>
