<template>
  <div class="sol-body">
    <el-card shadow="never">
      <div class="left-side">
      <el-collapse v-model="activeNames">
        <el-collapse-item
          v-for="(card, key) in abi_list"
          :key="key"
          :name="key"
          class="sol-card"
        >
          <template slot="title">
            <el-tag
              size="mini"
              :key="card.stateMutability"
              :type="{ view: '', nonpayable: 'warning' }[card.stateMutability]"
              effect="dark"
              class="tag"
            >
              {{ card.stateMutability }}
            </el-tag>
            <div class="card-name">{{ card.name }}</div>
          </template>
          <InputBlock
            :card="card"
            :contract="contract"
            :web3="web3"
            @result="send_result"
          />
        </el-collapse-item>
      </el-collapse>
    </div>
    </el-card>

    <div class="right-side">
      <el-card class="box-card" v-for="(body, key) in jsons" :key="key">
        <div slot="header" class="clearfix">
          <span>调用函数: {{ body.function_name }}</span>
        </div>
        <json-viewer :value="body.result" v-if="isJson(body.result)"></json-viewer>
        <div v-else>
          {{body.result}}
        </div>
      </el-card>
    </div>
    
  </div>
</template>

<script>
import InputBlock from "./input_block.vue";
export default {
  components: { InputBlock },
  name: "HelloWorld",
  props: ["address", "abi", "web3"],
  data() {
    return {
      activeNames: [],
      contract: null,
      jsons: [],
    };
  },
  methods: {
    send_result(e) {
       window.console.log('send_result',e)
      let self = this;
      self.jsons.push(e);
    },
     isJson(str) {
        window.console.log(str)
        try {
            if (typeof str == "object") {
                return true;
            }
        } catch(e) {
          window.console.log('error',e)
        }
        return false;
    }
  },
  mounted() {
    let self = this;
    self.contract = new web3.eth.Contract(self.abi, self.address);
  },
  computed: {
    abi_list() {
      let self = this;
      let list_ = self.abi?self.abi:[]
      return list_.filter((e) => {
        return e.type == "function";
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sol-body {
  display: flex;
  align-items: top;
  .left-side,
  .right-side {
    height: 100vh;
    overflow-y: scroll;

    flex: 1;
  }
  .right-side {
    margin-left: 20px;
    .box-card{
      margin-bottom:10px
    }
  }
  .sol-card {
    margin-bottom: 10px;
    font-size: 15px;
    .card-name {
      font-size: 14px;
      font-weight: normal;
    }
    .tag {
      margin-right: 10px;
      font-size: 12px;
    }
  }
}
</style>
