<template>
  <el-dialog title="创建合约模型" :visible.sync="show_dialog">
    <el-form :model="form">
      <el-form-item label="项目名称" label-width="100px">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="项目地址" label-width="100px">
        <el-input v-model="form.address" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="ABI" label-width="100px">
        <el-input
          type="textarea"
          v-model="form.abi"
          autocomplete="off"
          rows="5"
        ></el-input>
      </el-form-item>
      <el-form-item label="链名" label-width="100px">
         <el-radio-group v-model="form.chain">
      <el-radio-button label="Main" name="type"></el-radio-button>
      <el-radio-button label="Ropsten" name="type"></el-radio-button>
      <el-radio-button label="Rinkby" name="type"></el-radio-button>
      <el-radio-button label="Kovan" name="type"></el-radio-button>
      <el-radio-button label="Goerli" name="type"></el-radio-button>
      <el-radio-button label="BSC主网" name="type"></el-radio-button>
      <el-radio-button label="BSC测试网" name="type"></el-radio-button>
      <el-radio-button label="HECO主网" name="type"></el-radio-button>
      <el-radio-button label="HSC虎符主网" name="type"></el-radio-button>
      <el-radio-button label="FTM" name="type"></el-radio-button>
      <el-radio-button label="Matic" name="type"></el-radio-button>
    </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show_dialog = false">取 消</el-button>
      <el-button type="primary" @click="submit_model">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
const ls = window.localStorage;
export default {
  data() {
    return {
      show_dialog: false,
      form: {
        name: "",
        address: "",
        abi: "",
        chain:"Ropsten"
      },
    };
  },
  methods: {
    open() {
      let self = this;
      self.show_dialog = true;
    },
    close() {
      let self = this;
      self.show_dialog = false;
    },
    submit_model() {
      let self = this;
      let f = self.form;

      self.new_sol(f.name, f.address, f.abi,f.chain);
      self.form = {
        name: "",
        address: "",
        abi: "",
      };
      self.show_dialog = false;
    },
    get_ls() {
      let self = this;
      let sols = ls.getItem("sols");
      if (!sols) {
        ls.setItem("sols", "[]");
        sols = "[]";
      }
      return JSON.parse(sols);
    },
    new_sol(name, address, abi,chain) {
      let self = this;
      let sols = self.get_ls();
      abi = JSON.parse(abi);
      //if (abi.abi) {
      //  abi = abi;
      //}
      const info = {
        name: name,
        address: address,
        abi: abi,
        chain:chain
      };
      sols.push(info);
      ls.setItem("sols", JSON.stringify(sols));
      self.$emit("refresh");
    },
  },
};
</script>
