<template>
  <div class="input-block" v-loading="loading">
    <div v-for="(input, key) in card.inputs" :key="key" class="input">
      <el-input v-model="inputs[key]" :placeholder="input.type"></el-input>
    </div>
    <div class="input" v-if="cardType == 'payable'">
      <el-input placeholder="请输入代币数量" v-model="input_value">
        <template slot="prepend">代币数量</template>
      </el-input>
    </div>
    <el-button type="primary" @click="apply(card.stateMutability)"
      >运行</el-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputs: [],
      result: "",
      loading: false,
      input_value: 0,
      decimal: 18,
    };
  },
  methods: {
    connectMetaMask: async function() {
      let self = this;
      let connected = await self.isMetaMaskConnected();
      if (connected) {
        await window.ethereum.enable();
        self.show_detail = true;
      }
    },
    isMetaMaskConnected: async () => {
      let accounts = await self.web3.eth.getAccounts();
      return accounts.length > 0;
    },
    apply(type) {
      let self = this;
       window.console.log(type);
      if (type == "view") {
        self.call();
      } else if (type == "nonpayable") {
        self.run();
      } else {
        self.run();
      }
    },
    async run() {
      let self = this;
      let method = self.contract.methods[self.card.name];
      //这里要考虑数据类型
      let accounts = await web3.eth.getAccounts();
      let addr = accounts[0];
      let values = [];
      for (var i = 0; i < self.card.inputs.length; i++) {
        let str_type = self.card.inputs[i].type;
        let input_str = self.inputs[i] + "";
         window.console.log("数据类型", str_type, self.inputs[i]);
        if (str_type.indexOf("[]") > -1) {
          //传递参数为 array 类型
          if (str_type.indexOf("uint") > -1) {
            //数字类型参数
            let uint_list = JSON.parse(self.inputs[i]);
            
            values[i] = uint_list;
          } else if (str_type.indexOf("address") > -1) {
            values[i] = JSON.parse(self.inputs[i]);
          } else {
             window.console.log("暂时无法解析类型", str_type);
          }
        } else {
          window.console.log("error");
        }
      }
       window.console.log("values", values);

      self.loading = true;
      let send_info = { from: addr };

      if (self.cardType == "payable") {
        let value = self.input_value;
        send_info["value"] = value;
      }
      try {
        let result = await method(...values).send(send_info);
        self.loading = false;
        self.input_value = 0;
        let result_info = {
          function_name: self.card.name,
          result: result,
        };
        self.$emit("result", result_info);
      } catch (e) {
        self.loading = false;
        self.$notify.error({
          title: "错误",
          message: e.message,
        });
      }
    },
    async call() {
      let self = this;
      let method = self.contract.methods[self.card.name];
      self.loading = true;
      try {
        let method_to_call = method(...self.inputs);
        let result = await method_to_call.call();
         window.console.log("method_to_call", method_to_call);

        self.loading = false;
        let result_info = {
          function_name: self.card.name,
          result: result,
        };
         window.console.log("result", result);
        self.$emit("result", result_info);
      } catch (e) {
        self.$notify.error({
          title: "错误",
          message: e.message,
        });
         window.console.log(e);
        self.loading = false;
      }
    },
  },
  computed: {
    cardType() {
      let self = this;
      return self.card.stateMutability;
    },
  },
  props: ["card", "contract", "web3"],
};
</script>
<style lang="scss">
.input-block {
  .input {
    margin-bottom: 10px;
  }
}
</style>
