export default {
  systemNavType: [
    {
      value: 1,
      label: "面包屑"
    },
    {
      value: 2,
      label: "窗口标签"
    }
  ]
};
