<template>
  <div class="home" v-loading="loading">
    <!-- <page-nav></page-nav> -->
    <div class="nav">
      <el-button type="primary" @click="add_sol" style="margin-bottom:10px;"
        >添加合约</el-button
      >
      <el-button type="danger" @click="del_sol" style="margin-bottom:10px;"
        >删除当前合约</el-button
      >
      <div class="float-right">当前地址:{{ wallet_address }}</div>
    </div>
    <div class="contract-list" v-if="db.length">
      <el-tabs
        type="border-card"
        tab-position="left"
        v-if="db.length"
        v-model="now_address_id"
      >
        <el-tab-pane :label="sol.name" v-for="(sol, key) in db" :key="key">
          <el-descriptions
            :labelStyle="{width:'80px'}"
            class="margin-top"
            title="合约基础信息"
            :column="3"
            size="middle"
            border
            style="margin-bottom:10px;"
          >
            <template slot="extra">
              <el-button type="primary" size="small">切换到对应链条</el-button>
            </template>
            <el-descriptions-item>
              <template slot="label">
                项目名称
              </template>
              {{now_info.name}}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                链条
              </template>
              {{now_info.chain}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                合约地址
              </template>
              {{now_info.address}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                备注
              </template>
              --
            </el-descriptions-item>
          </el-descriptions>

          <div
            slot="label"
            class="nav-label"
            @click="select_contract(sol.address)"
          >
            <div class="labe-left">{{ sol.name }}</div>
          </div>
          <sol-body
            :address="sol.address"
            :abi="sol.abi"
            :web3="web3"
          ></sol-body>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-empty v-else>
      <el-button type="primary" @click="add_sol" style="margin-bottom:10px;"
        >添加合约</el-button
      >
    </el-empty>
    <new-contract ref="newContract" @refresh="refresh_page"></new-contract>
  </div>
</template>

<script>
import NewContract from "@/components/newContract.vue";
import PageNav from "@/components/nav.vue";
import Web3 from "web3";
const ls = window.localStorage;
import SolBody from "../components/SolBody.vue";
export default {
  name: "Home",
  data() {
    return {
      db: [],
      loading: true,
      now_address_id: 0,
      now_address: null,
      web3: null,
      wallet_address: "",
    };
  },
  async mounted() {
    let self = this;
    let web3Provider;
    
     if (window.ethereum) {
      web3Provider = window.ethereum;
      try {
        // 请求用户授权
        await window.ethereum.enable();
      } catch (error) {
        alert("error当前无法链接钱包");
      }
    } else if (window.web3) {
      // 老版 MetaMask Legacy dapp browsers...
      web3Provider = window.web3.currentProvider;
    } else {
      alert("当前无法链接钱包");
    }
     
    web3 = new Web3(web3Provider);
    self.web3 = web3;
    let accounts = await self.web3.eth.getAccounts();
    if (accounts.length) {
      self.wallet_address = accounts[0];
      
    }
    self.refresh_page();
    
    if (self.db.length) {
      self.now_address_id = 0;
    }
  },

  methods: {
    slim_address(addr){
      return `${addr.slice(0,4)}...${addr.slice(-4)}`
    },
    select_contract(addr) {
      let self = this;
      let index = self.db.findIndex((e) => {
        return e.address == addr;
      });
      ls.setItem("now_address_id", index);
      self.now_address = addr;
    },
    del_sol() {
      let self = this;
      const now_contract_id = ls.getItem("now_address_id");
      const address = self.db[now_contract_id].address;

      self
        .$confirm("此操作将永久删除该合约信息, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let sols = self.get_ls();
          sols = sols.filter((e) => {
            return e.address != address;
          });
          ls.setItem("sols", JSON.stringify(sols));
          ls.setItem("now_address_id", 0);
          self.now_address_id = "0";
          self.refresh_page();
        });
    },
    add_sol() {
      let self = this;
      self.$refs.newContract.open();
    },
    get_ls() {
      let self = this;
      let sols = ls.getItem("sols");
      
      if (!sols) {
        ls.setItem("sols", "[]");
        sols = "[]";
      }
      return JSON.parse(sols);
    },

    refresh_page() {
      let self = this;
      self.loading = false;
      let sols = self.get_ls();


      self.db = sols;
      self.loading = false;
    },
  },
  computed:{
    now_info(){
      let self = this
      let now_info = self.db[self.now_address_id]
      return now_info
    }
  },
  components: {
    SolBody,
    NewContract,
  },
};
</script>
<style lang="scss">
.home {
  .nav {
    display: flex;
    align-items: center;
    .float-right {
      margin-left: auto;
    }
  }

  .nav-label {
    display: flex;
    .label-left {
      max-width: 100px;
    }
    .label-right {
      margin-left: auto;
    }
  }
}
</style>
