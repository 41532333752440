<template>
     <div class="nav">
      <el-button type="primary" @click="add_sol" style="margin-bottom:10px;"
        >添加合约</el-button
      >
      <el-button type="danger" @click="del_sol" style="margin-bottom:10px;"
        >删除当前合约</el-button
      >
      <div class="float-right">当前地址:{{ wallet_address }}</div>
    </div>
</template>
<script>
export default {
    data(){
        return {}
    },
    methods:{
        
    }
}
</script>