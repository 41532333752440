import Vue from "vue";
import VueRouter from "vue-router";
import home from "@/views/Home.vue";

import Dashboard from "./dashboard.js";

Vue.use(VueRouter);

export const contentRoute = [...Dashboard];

const routes = [
  {
    path: "/",
    component: home,
    name: "Dashboard",
    meta: {
      title: "首页"
    },
    children: contentRoute
  }
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  routes
});

export default router;
